<template>
    <div class="payment-success">
        <div class="container-md">
            <img src="@/assets/images/payment_thankyou.png" alt="Successful" class="img-fluid">
            <h2 class="my-3">THANK YOU !</h2>
            <h3>We have sent the payment receipt to your email,<br> be ready to explore SOCA AI platform.</h3>
            <button class="btn btn-purple mt-4 mt-md-5 btn-lg" @click="handleBackHome"><h4 class="mb-0 mx-md-3 my-md-2">Back to Creator</h4></button>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        setTimeout(() => {
            const redirectPath = this.$route.query.redirect;

            if (redirectPath) {
                this.$router.push(redirectPath);
            } else {
                this.$router.push({ name : 'Home' })
            }
        }, 3000);
    },
    methods: {
        handleBackHome() {
            const redirectPath = this.$route.query.redirect;

            if (redirectPath) {
                this.$router.push(redirectPath);
            } else {
                this.$router.push({ name : 'Home' })
            }
        }
    }
}
</script>

<style scoped>
h2 {
    font-size: 30px;
}

h3 {
    font-size: 22px;
    font-weight: normal;
}

.payment-success {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.btn-purple{
  background-color: #6D75F6;
  color: white;
}

@media only screen and (max-width: 800px) {
    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 16px;
    }

    h4 {
        font-size: 14px;
    }
}
</style>